<template>
  <v-card flat>
    <v-row no-gutters class="pt-8 pl-8 pr-8" justify="center" align="center">
      <v-col cols="auto">
        <v-card class="pt-2 pb-2 text-center" flat color='transparent'>
          <v-btn icon id="previousMonth" width="50px" @click="flickToPrevMonth()" :disabled="this.loading"
            v-if="prevMonthEnabled">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-card>
      </v-col>
      <v-col cols="auto">
        <v-card class="pa-0 text-h4 font-weight-bold text-center" min-width="22rem" flat color='transparent'>
          <v-btn v-if="this.showLoadTodaysMonth" icon style="margin-right: 5px;"
            id="loadTodaysMonth" @click="loadTodaysMonth()" :disabled="this.loading">
            <v-icon>mdi-calendar-star</v-icon>
          </v-btn>
          <span v-else id="todaysMonthButtonPlaceholder" style="display: inline-block; width: 24px; height: 20px;"></span>
          <label id="monthName">{{ this.displayedDate.format('MMMM YYYY') }}{{ rightText }}</label>
          <v-btn icon id="reload" @click="reload()" :disabled="this.loading">
            <v-icon :class="this.loading ? 'rotate' : ''">mdi-cached</v-icon>
          </v-btn>
        </v-card>
      </v-col>
      <v-col cols="auto">
        <v-card class="pt-2 pb-2 text-center" flat color='transparent'>
          <v-btn icon id="nextMonth" width="50px" @click="flickToNextMonth()" :disabled="this.loading">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="bottomText" no-gutters class="pl-8 pr-8" justify="center" align="center">
      <v-col cols="auto">
      </v-col>
      <v-col cols="auto">
        <label id="bottomText" class="pa-0 text-h4 font-weight-bold text-center">
          {{ bottomText }}
        </label>
      </v-col>
      <v-col cols="auto">
      </v-col>
    </v-row>
    <v-row no-gutters class="pl-8 pr-8" justify="center" align="center">
      <div id="bottomTemplate">
        <slot name="bottomTemplate"></slot>
        <!-- 
            ----- BEISPIEL -----
            <Flicker>
              <template v-slot:bottomTemplate>
                <b>Fat Text</b>
              </template>
            </Flicker>
         -->
      </div>
    </v-row>

  </v-card>
</template>

<script lang="js">
import dayjs from '@util/dayjs';

export default {
  name: 'Flicker',
  props: [ 'loading', 'rightText', 'bottomText' ],

  computed: {
    displayedDate: function () {
      return this.$store.state.displayedMonth;
    },

    showLoadTodaysMonth: function() {
      return dayjs().isSame(this.displayedDate, 'month') == false;
    },

    prevMonthEnabled: function () {
      return this.displayedDate.isAfter(dayjs('2020-07-31'));
    }
  },

  methods: {
    reload() {
      this.$root.$emit('reloadApp', this);
    },

    flickToPrevMonth() {
      this.$emit('changeRoute', this.displayedDate.subtract(1, 'months'));
    },

    flickToNextMonth() {
      this.$emit('changeRoute', this.displayedDate.add(1, 'months'));
    },

    loadTodaysMonth() {
      this.$emit('changeRoute', dayjs().date(1));
    }
  }
}
</script> 

<style type="scss">
.rotate {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>