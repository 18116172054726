<template lang="html">
  <section class="month">
    <v-overlay data-cy="loading-overlay" :value="this.showProjectLoadingOverlay" absolute />

    <Flicker :loading="this.loading"
      :bottomText="this.projectData && this.projectData.name ? `${this.projectData.name} (${this.projectData.identifier})` : ''"
      @changeRoute="this.changeRoute">
      <template v-if="this.projectData && this.projectData.name" v-slot:bottomTemplate>
        <label id="bottomText" class="pa-0 text-h5 font-weight-bold text-center">
          {{`(Projektlead: ${projectData.leader.firstname} ${projectData.leader.lastname})`}}
        </label>
      </template>
    </Flicker>

    <br><br>
    <div v-if="this.showProject">

      <!-- Projekte & Aufgaben -->
      <v-row no-gutters>
        <v-col cols="0" md="1"></v-col>
        <v-col cols="auto" md="10">
          <ProjectActivities :activities="this.projectData.activities" type="projectmonth" :entryUpdateEnabled="false"></ProjectActivities>
        </v-col>
        <v-col cols="0" md="1"></v-col>
      </v-row>

      </div>
    <v-row v-else-if="!this.loading" no-gutters class="d-flex justify-center">
      Keine Daten vorhanden
    </v-row>

    <!-- /Projektabschluss -->
    <Confirm ref="confNotFound" confirmButtonText="Weiter" :cancelButtonText="null"></Confirm>

    <v-snackbar id="errorMessage" v-if="error.show" v-model="error.show" color="red accent-2" timeout="5000" top app centered elevation="6">
      {{ error.message }}
    </v-snackbar>
  </section>
</template>

<script lang="js">
import _ from "lodash"

import Flicker from '@components/Flicker.vue';
import Confirm from '@components/Confirm.vue';
import ProjectActivities from '@components/user/ProjectActivities.vue'
import ActivityFilter from '@/mixins/ActivityFilter.vue'

import * as sqtClient from '@client/client.sqt';


export default {
  name: 'TeamleadProjektansicht',
  components: {
    ProjectActivities,
    Confirm,
    Flicker
  },
  mixins: [ActivityFilter],
  props: [],

  created() {
    this.$root.$on('startLoading', this.startLoading);
    this.$root.$on('reload', this.reloadProject);
  },

  mounted() {
    this.initialLoadProject();
  },

  beforeDestroy() {
    this.$root.$off('startLoading', this.startLoading);
    this.$root.$off('reload', this.reloadProject);
  },

  data() {
    return {
      loading: false,
      initialProjectLoad: true,
      projectData: {},
      tasksByUser: [],
      error: { 
        show: false,
        message: ''
      }
    }
  },

  computed: {
    /* tasksByUser: function() {
      // Wieso auch immer ist das ein infinite loop
      return this.createChildData(_.cloneDeep(this.projectData.activities), this.structureDefinition.user);
    },*/

    showProject: function () {
      return (this.initialProjectLoad == false) && (this.projectData.activities) && (this.projectData.activities.length > 0);
    },

    showProjectLoadingOverlay: function () {
      return this.loading && this.initialProjectLoad == false;
    },

    displayedDate: function () {
      return this.$store.state.displayedMonth;
    },

    structureSelectionChanged: function () {
      return !_.isEqual(this.structure, this.tempStructure);
    }
  },

  methods: {
    startLoading() {
      this.loading = true;
    },

    loadedProjectID() {
      return this.$route ? this.$route.query.projectID : undefined;
    },

    /// Nur für einfacheres testing...
    async loadProjectData(projectID, month, year) {
      const staticUserData = this.$store.getters.staticUserData;
      const startOfMonth = this.displayedDate.startOf('month').startOf('day');
      const endOfMonth = this.displayedDate.endOf('month').endOf('day');

      // TODO wieso nicht gleich ein "getMonatsdaten" Aquivalent machen? So ist das doch ziemlich unsauber nacher "getMonatsdaten" aufzurufen
      const projectLeadActivities = await sqtClient.getMocoProjectLeadActivities(projectID, startOfMonth, endOfMonth);
      
      const monthData = await sqtClient.getMonatsdaten(
        month, year,
        projectLeadActivities.activities, [],
        [], [], staticUserData.schedules,
        this.$store.getters.enabledRules
      );

      const monthActivities = monthData.weeks.flatMap(week => {
        return week.days;
      }).flatMap(day => {
        return day.activities;
      });

      const projectData = {
        ...monthData,
        ...{
          name: projectLeadActivities.project.name,
          identifier: projectLeadActivities.project.identifier,
          leader: {
            id: projectLeadActivities.project.leader.id,
            firstname: projectLeadActivities.project.leader.firstname,
            lastname: projectLeadActivities.project.leader.lastname,
          },
          closing: {
            jiraKey: undefined,
            status: undefined,
            latestComment: undefined,
            updated: undefined,
          },
          activities: monthActivities
        }
      }

      return projectData;
    },

    async initialLoadProject() {
      this.initialProjectLoad = true;
      await this.loadProject();
    },

    async reloadProject() {
      await this.loadProject();
    },

    async loadProject() {
      this.loading = true;
      const res = await this.$draf(async () => {
        if (this.$store.getters.userAuthenticationData.mocoApiKey == undefined) {
          return;
        }

        let projectData;
        try {
          projectData = await this.loadProjectData(this.loadedProjectID(),
            this.displayedDate.isoMonth(), this.displayedDate.year());
        } catch (err) {
          await this.openConfirmation(this.$refs.confNotFound, 'Projekt nicht gefunden',
            'Das aufgerufene Projekt existiert nicht. Sie werden nun zum aktuellen Monat weitergeleitet.')

          return () => { this.$router.push({ path: global.ROUTES.aktuellermonat.path }); }
        }

        this.tasksByUser = this.getTasksByUser(projectData.activities);
        this.projectData = projectData;
      });

      this.initialProjectLoad = false;
      this.loading = false;
      if (typeof (res) == 'function') {
        res();
      }
    },

    async openConfirmation(confirm, title, message) {
      this.loading = false;
      return await confirm.open(title, message);
    },

    changeRoute(displayedDate) {
      this.$router.push({
        name: global.ROUTES.teamleadprojekt.name,
        params: {
          year: displayedDate.year(),
          month: displayedDate.isoMonth()
        },
        query: {
          projectID: this.loadedProjectID()
        }
      });
    }
  },

  watch: {
    displayedDate: function () {
      this.initialLoadProject();
    }
  },
}
</script>

<style type="scss">
.v-input {
  font-size: 14px;
}
</style>